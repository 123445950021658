@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Roboto", sans-serif;
}

.development__slider .swiper-button-next {
  @apply right-0 xl:!right-[-30px];
}
.development__slider .swiper-button-next::after {
  @apply text-white;
}
.development__slider .swiper-button-prev {
  @apply left-0 xl:!left-[-30px];
}
.development__slider .swiper-button-prev::after {
  @apply text-white;
}

/* .brand__slider .swiper-button-next {
  @apply right-0 md:!right-[-30px];
}
.brand__slider .swiper-button-next::after {
  @apply text-white;
}
.brand__slider .swiper-button-prev {
  @apply left-0 md:!left-[-30px];
}
.brand__slider .swiper-button-prev::after {
  @apply text-white;
} */

.game__slider .swiper-button-next {
  @apply right-0 xl:!right-[-30px];
}
.game__slider .swiper-button-next::after {
  @apply text-white;
}
.game__slider .swiper-button-prev {
  @apply left-0 xl:!left-[-30px];
}
.game__slider .swiper-button-prev::after {
  @apply text-white;
}

.digital__slider .swiper-button-next {
  @apply right-0 xl:!right-[-30px];
}
.digital__slider .swiper-button-next::after {
  @apply text-white;
}
.digital__slider .swiper-button-prev {
  @apply left-0 xl:!left-[-30px];
}
.digital__slider .swiper-button-prev::after {
  @apply text-white;
}
.brand__slider .swiper-button-next {
  @apply right-0 xl:!right-[-30px];
}
.brand__slider .swiper-button-next::after {
  @apply text-white;
}
.brand__slider .swiper-button-prev {
  @apply left-0 xl:!left-[-30px];
}
.brand__slider .swiper-button-prev::after {
  @apply text-white;
}
